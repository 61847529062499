<template>
  <v-text-field
    v-model="colorInput"
    hide-details
    outlined
    clearable
    v-bind="$attrs"
    maxlength="7"
    @click:clear="$emit('input', null)"
  >
    <template #append>
      <v-menu
        v-model="menu"
        bottom
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <div
            :style="swatchStyle"
            class="mt-n1"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="color"
              flat
              mode="hexa"
              @update:color="(color) => $emit('input', color.hex)"
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      color: this.value,
      menu: false,
    }
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        border: color ? null : '1px solid',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
    colorInput: {
      get () {
        return this.color.hex || this.color
      },
      set (val) {
        this.color = val
        this.$emit('input', val)
      },
    },
  },
  watch: {
    value (val) {
      this.color = val
    },
  },
}
</script>
